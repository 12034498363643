<template>
	<section class="domain_content">
		<!-- About Us -->
		<div class="sub-page">
			<h1>About Us</h1>
			<p>
				Welcome!
			</p>
			<p>
				Our site provides GB WhatsApp APK and will update information about the app. Some alternatives of modified WhatsApp app will also be introduced here.
			</p>
			<p>
				You can always find the latest version without risks here. 
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'This page is about what we do. We provide GB WhatsApp apk source for free.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.com/about-us/"
            }]
	},
}
</script>
  